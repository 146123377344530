import React from "react"

import HomeContainer from "../components/containers/HomeContainer"
import HeadData from "../data/HeadData"
import PageHeroHeader from '../components/headers/PageHeroHeader'
import HeaderNav from '../components/navigation/HeaderNav'
import PagePrivacySection from "../components/sections/PagePrivacySection"

export default () => (
  <HomeContainer>
    <HeadData title='Privacy Policy' />

    <PageHeroHeader>
      <HeaderNav isHome />
    </PageHeroHeader>

    <PagePrivacySection />
  </HomeContainer>
)